import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {},
  reducers: {
    getSettings: (state, action) => {
      state[action.payload.id] = action.payload.response.data;
    },
    updateSettings: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload.settings
      };
    },
    updateSettingsCommand: (state, action) => {
      state[action.payload.id].command[action.payload.type][action.payload.name].enabled = action.payload.enabled;
    },
    updateSettingsCommandChannels: (state, action) => {
      state[action.payload.id].command[action.payload.type][action.payload.name].channels = action.payload.channels;
    },
    updateIntegrationSetting: (state, action) => {
      state[action.payload.id].roleManagement.integrations[action.payload.slug] = action.payload.setting;
    }
  }
});

export const {
  getSettings,
  updateSettings,
  updateSettingsCommand,
  updateSettingsCommandChannels,
  updateIntegrationSettingp
} = settingsSlice.actions;
export default settingsSlice.reducer;
