import io from 'socket.io-client';
// Websocket init
// connect to API

function socket() {
  // console.log(process.env.REACT_APP_LOGIN);
  const socket = io.connect(process.env.REACT_APP_LOGIN, { transports: ['websocket'] });
  socket.on('connect', () => {
    if (process.env.NODE_ENV !== 'production') console.log('socket connected?', !socket.disconnected, socket.id);
  });
  socket.on('disconnect', () => {
    if (process.env.NODE_ENV !== 'production') console.log('socket disconnect?', !socket.disconnected, socket.id);
  });
  socket.onAny((eventName, ...args) => {
    if (process.env.NODE_ENV !== 'production') console.log('socket onAny', eventName, args);
  });
  return socket;
}

export default socket;

// RECEIVE
// Server control panel update message
// "SETTINGS_UPDATED"
// Always triggered if you updated or anyone else

// 2WAY FLOW
// Account sync
// API/auth/battlenet
// all query params

// API/battlenet/watch?accountID=[bnetid]
// websocket in header: socket: [socketId]

// API/battlenet/sync-account?accountID=[bnetId]
