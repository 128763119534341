import { setResponseError } from '../store/error.store';

const errors = {
  CHARACTER_SCAN_FAILED_INFO_BLURB: 'Make sure you have logged into this character recently',
  ALL_CHARACTERS_FAILED:
    "Check to make sure battlenet isn't down for maintenance, Also ensure that you did not turn off the ability for third party developers to scan your characters in your blizzard account settings."
};

export const getReadableApiError = (code) => errors[code] || 'Some error occurred.';

export const handleResponseError = (response, message, dispatch) => {
  dispatch(
    setResponseError({
      response,
      message
    })
  );
};
