export async function request(uri, auth) {
  let result;
  if (auth) {
    result = await fetchAuth(uri);
  } else {
    result = await fetchClean(uri);
  }
  return result;
}

export async function fetchClean(uri) {
  try {
    console.log('fetchClean', process.env.REACT_APP_API + uri);
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

const getUserFromLocalStorage = () => {
  const localStorageUser = localStorage.getItem('user');
  return localStorageUser ? JSON.parse(localStorageUser) : null;
};

export async function fetchAuth(uri) {
  const user = getUserFromLocalStorage();
  if (!user) {
    return null;
  }

  try {
    console.log('fetchAuth', process.env.REACT_APP_API + uri);
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.bearerToken}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function post(uri, data) {
  const user = getUserFromLocalStorage();
  if (!user) {
    return null;
  }
  try {
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.bearerToken}`
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function get(uri, headers = {}) {
  const header = { Accept: 'application/json', ...headers };
  try {
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'GET',
      headers: header
    });
    return await response.json();
  } catch (error) {
    return null;
  }
}

export async function put(uri, data) {
  const user = getUserFromLocalStorage();
  if (!user) {
    return null;
  }
  try {
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.bearerToken}`
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function patch(uri, data) {
  const user = getUserFromLocalStorage();
  if (!user) {
    return null;
  }
  try {
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.bearerToken}`
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function del(uri) {
  const user = getUserFromLocalStorage();
  if (!user) {
    return null;
  }
  try {
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${user.bearerToken}`
      }
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function update(uri, data) {
  const user = getUserFromLocalStorage();
  if (!user) {
    return null;
  }
  try {
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.bearerToken}`
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateWithToken(uri, data, bearerToken) {
  try {
    const response = await fetch(process.env.REACT_APP_API + uri, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`
      },
      body: JSON.stringify(data)
    });
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
}
