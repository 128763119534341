import { createSlice } from '@reduxjs/toolkit';

const serversSlice = createSlice({
  name: 'servers',
  initialState: {},
  reducers: {
    getServers: (state, action) => {
      if (action.payload) {
        const sorted = action.payload.sort((a, b) => {
          if (b.hasJeeves > a.hasJeeves) {
            return 1;
          }
          if (b.hasJeeves < a.hasJeeves) {
            return -1;
          }
          return b.name > a.name;
        });
        sorted.forEach((s) => (state[s.id] = s));
      }
    }
  }
});

export const { getServers } = serversSlice.actions;
export default serversSlice.reducer;
