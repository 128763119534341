import { createSlice } from '@reduxjs/toolkit';

const generalSlice = createSlice({
  name: 'general',
  initialState: {
    sidebarShow: false,
    info: null,
    alert: {
      visible: false,
      text: '',
      severity: ''
    }
  },
  reducers: {
    getInfo: (state, action) => {
      state.info = action.payload;
    },
    toggleSidebar: (state) => (state.sidebarShow = !state.sidebarShow),
    showAlert: (state, action) => {
      state.alert = {
        ...action.payload,
        visible: true
      };
    },
    hideAlert: (state) => {
      state.alert.visible = false;
    }
  }
});

export const { getInfo, toggleSidebar, showAlert, hideAlert } = generalSlice.actions;
export default generalSlice.reducer;
