import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthPage from '../views/auth/AuthPage';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Dashboard Routes
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <SelectServer /> },
        {
          path: '/:serverId',
          children: [
            {
              path: '/',
              element: <Navigate to="settings" />
            },
            { path: '/settings', element: <Settings /> },
            {
              path: '/commands',
              element: <CommandsLanding />
            },
            {
              path: '/rolemanagement',
              children: [
                {
                  path: '/',
                  element: <RoleManagement />
                },
                {
                  path: '/:integrationSlug',
                  element: <Integrations />
                }
              ]
            },
            {
              path: '/guild',
              children: [
                {
                  path: '/',
                  element: <GuildLanding />
                },
                {
                  path: '/add',
                  element: <AddGuild />,
                  exact: true
                },
                {
                  path: '/:guildId',
                  element: <EditGuild />
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/bnet',
      element: <AuthPage />
    },
    {
      path: '/discord',
      element: <Discord />
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [{ path: '/', element: <LandingPage /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const Integrations = Loadable(lazy(() => import('../pages/rolemanagement/Integrations')));
const Settings = Loadable(lazy(() => import('../pages/Settings')));
const RoleManagement = Loadable(lazy(() => import('../pages/rolemanagement/Landing')));
const SelectServer = Loadable(lazy(() => import('../pages/SelectServer')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
// Commands
const CommandsLanding = Loadable(lazy(() => import('../pages/commands/Landing')));
// Guild
const GuildLanding = Loadable(lazy(() => import('../pages/guild/Landing')));
const EditGuild = Loadable(lazy(() => import('../pages/guild/EditGuild')));
const AddGuild = Loadable(lazy(() => import('../pages/guild/AddGuild')));
const Discord = Loadable(lazy(() => import('../pages/Discord')));
