/* eslint-disable */
import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { getBattlenetWatch, getBnetSession } from '../../api/battlenet.api';
import CharacterProfile from './CharacterProfile';
import SocketHandlers from '../../defines/SocketHandlers';
import useAuth from '../../hooks/useAuth';
import { syncLocalStorage } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setCharacters } from 'src/store/characters.store';

const successCircle = makeStyles((theme) => ({
  top: {
    color: 'green'
  }
}));

const warningCircle = makeStyles((theme) => ({
  top: {
    color: 'red'
  }
}));

const failedCircle = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    '&:hover': {
      'text-decoration': 'underline'
    }
  }
}));

const BattlenetInfoTypes = {
  CHARACTERS_DISCOVERED: 'CHARACTERS_DISCOVERED',
  CHARACTER_SCANNED: 'CHARACTER_SCANNED',
  CHARACTER_FAILED: 'CHARACTER_FAILED'
};

const Battlenet = (props) => {
  const dispatch = useDispatch();
  const successClass = successCircle();
  const warningClass = warningCircle();
  const failedClass = failedCircle();
  const [open, setOpen] = React.useState(true);
  const [showSync, setSync] = React.useState(false);
  const [passed, setPassed] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [chars, setChars] = React.useState({});
  const [events, setEvents] = React.useState([]);
  const [token, setToken] = React.useState(null);
  const [currentMain, setCurrentMain] = React.useState(null);
  let externalWindow = null;
  let closeCheck = null;
  const { currentUser, setCurrentUser } = useAuth();
  const characters = useSelector((state) => state?.characters);

  React.useEffect(() => {
    openWindow();
  }, []);

  const openWindow = () => {
    getBnetSession(currentUser.bearerToken).then((resp) => {
      if (resp.data) {
        externalWindow = window.open(
          `${process.env.REACT_APP_LOGIN}/auth/battlenet?session=${resp.data}&region=eu`,
          '',
          'width=600,height=400,left=200,top=200'
        );
        window.addEventListener('message', handleAuthorization.bind(this), false);
        closeCheck = setInterval(() => handleCloseWindow(externalWindow.closed), 5000);
      } else {
        console.error('No session data received.');
      }
    });
  };

  const handleCloseWindow = (closed) => {
    console.log('close ', closed);
    if (closed) {
      if (!passed || !failed) {
        setFailed(true);
        clearInterval(closeCheck);
      }
    }
  };

  React.useEffect(() => {
    setChars((prev) => {
      console.log(prev);
      const dict = { ...prev };
      Object.keys(dict).map((region) => {
        Object.keys(dict[region]).map((id) => {
          console.log(Number(id) === currentMain, id, currentMain);
          if (Number(id) === currentMain) {
            dict[region][id] = { ...dict[region][id], main: true };
          } else {
            if (dict[region][id].main) {
              dict[region][id] = {
                ...dict[region][id],
                main: false
              };
            }
          }
        });
      });
      console.log(dict);
      return dict;
    });
    const dict = { ...characters };
    Object.keys(dict).map((region) => {
      Object.keys(dict[region]).map((id) => {
        console.log(Number(id) === currentMain, id, currentMain);
        if (Number(id) === currentMain) {
          dict[region][id] = { ...dict[region][id], main: true };
        } else {
          if (dict[region][id].main) {
            dict[region][id] = {
              ...dict[region][id],
              main: false
            };
          }
        }
      });
    });
    dispatch(setCharacters(dict));
  }, [currentMain]);

  React.useEffect(() => {
    if (events?.length) {
      const event = events.shift();
      setEvents((prev) => {
        return prev.filter((item) => {
          return item !== event;
        });
      });
      const eventType = event.code;
      if (eventType === BattlenetInfoTypes.CHARACTERS_DISCOVERED) {
        discoveredCharHandler(event);
        console.log(event);
      } else if (eventType === BattlenetInfoTypes.CHARACTER_SCANNED) {
        scannedCharHandler(event);
      } else if (eventType === BattlenetInfoTypes.CHARACTER_FAILED) {
        failedCharHandler(event);
      } else {
        console.log('Ignoring event.');
      }
    }
  }, [events]);

  const scannedCharHandler = (event) => {
    console.log(event);
    setChars((dict) => {
      console.log(dict);
      const region = event.data.region;
      const id = event.data.id;
      const spec = event.data.spec;
      dict[region][id]['stage'] = 'SCANNED';
      dict[region][id]['spec'] = spec;
      dict[region][id]['main'] = event.data.main;
      dict[region][id]['hidden'] = event.data.hidden;
      dict[region][id]['ignored'] = event.data.ignored;
      return dict;
    });
  };

  const failedCharHandler = (event) => {
    console.log('FAIL');
    setChars((dict) => {
      const region = event.data.region;
      const id = event.data.id;
      dict[region][id]['stage'] = 'FAILED';
      return dict;
    });
  };

  const discoveredCharHandler = (event) => {
    setChars((dict) => {
      console.log(dict);
      const region = event.data.region;
      dict[region] = {};
      event.data.data.map((item) => {
        dict[region][item.id] = {
          ...item,
          stage: 'QUEUED',
          accountID: event.accountID
        };
      });
      console.log(dict);
      return dict;
    });
  };

  function processBattlenetInfo(event) {
    const eventType = event.code;
    if (eventType === BattlenetInfoTypes.CHARACTERS_DISCOVERED) {
      setEvents((prev) => [...prev, event]);
    } else if (eventType === BattlenetInfoTypes.CHARACTER_SCANNED) {
      setEvents((prev) => [...prev, event]);
    } else if (eventType === BattlenetInfoTypes.CHARACTER_FAILED) {
      console.log(event);
      setEvents((prev) => [...prev, event]);
    } else {
      console.log('Ignoring event.');
    }
  }

  const handleAuthorization = (event) => {
    console.log(event);
    const newUrl = event.origin;
    if (newUrl.includes(`${process.env.REACT_APP_LOGIN}`)) {
      if (event.data.battleNetToken) {
        props.socket_manager.addHandler(SocketHandlers.BATTLENET_INFO, processBattlenetInfo);
        getBattlenetWatch(event.data.id, props.socket_manager.getID(), event.data.battleNetToken);
        setToken(event.data.battleNetToken);
        if (externalWindow && !externalWindow.closed) {
          externalWindow.close();
        }
        setPassed(true);
        clearInterval(closeCheck);
        const user = { ...currentUser, hasLinkedBnetAccount: true };
        setCurrentUser(user);
        syncLocalStorage(user);
        setTimeout(() => {
          setSync(true);
          //setOpen(false);
          // props.setBnet(false);
        }, 2000);
      } else {
        setFailed(true);
        clearInterval(closeCheck);
        if (externalWindow && !externalWindow.closed) {
          externalWindow.close();
        }
      }
    }
  };

  const handleClose = (value) => {
    setOpen(false);
    clearInterval(closeCheck);
    props.setBnet(false);
    if (externalWindow && !externalWindow.closed) {
      externalWindow.close();
    }
  };

  const handleTryAgain = () => {
    setPassed(false);
    setFailed(false);
    openWindow();
  };

  const waitingAction = () => (
    <div>
      <CircularProgress color="primary" style={{ width: '4rem', height: '4rem' }} />
      <br />
      <br />
      <DialogContentText>
        Awaiting battlenet auth ...
        <br />
        (Check the popup window.)
        <br />
        <br />* You may need to allow popup windows for this site.
      </DialogContentText>
    </div>
  );

  const successAction = (props) => (
    <div>
      {!showSync ? (
        <div>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              variant="static"
              className={successClass.top}
              style={{ width: '4rem', height: '4rem' }}
              value={100}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CheckIcon className={successClass.top} fontSize={'large'} />
            </Box>
          </Box>
          <br />
          <br />
          <DialogContentText>Successfully completed battlenet auth!</DialogContentText>
        </div>
      ) : (
        <div>
          {console.log(chars)}
          {Object.keys(chars)?.length ? (
            <div>
              {console.log(events)}
              {Object.keys(chars).map((region) => {
                return Object.values(chars[region]).map((value) => {
                  console.log(value);
                  return (
                    <CharacterProfile
                      data={value}
                      key={region + '-' + value.id}
                      token={token}
                      setCurrentMain={setCurrentMain}
                    />
                  );
                });
              })}
            </div>
          ) : (
            <div>
              <CircularProgress color="primary" style={{ width: '4rem', height: '4rem' }} />
              <DialogContentText>
                Syncing characters ... (Exiting this window will not stop the process.){' '}
              </DialogContentText>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const failedAction = () => (
    <div>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="static"
          className={warningClass.top}
          style={{ width: '4rem', height: '4rem' }}
          value={100}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PriorityHighIcon fontSize={'large'} className={warningClass.top} />
        </Box>
      </Box>
      <br />
      <br />
      <DialogContentText>
        An error occured trying to auth with battlenet.
        <br />
        <br />
        <a className={failedClass.link} onClick={handleTryAgain}>
          <b>Try again</b>
        </a>
      </DialogContentText>
    </div>
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      scroll="body"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title">Setup Battlenet</DialogTitle>
      <DialogContent style={{ margin: '10px', textAlign: 'center' }} dividers={true}>
        {!passed && !failed && waitingAction()}
        {passed && !failed && successAction()}
        {!passed && failed && failedAction()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default Battlenet;
