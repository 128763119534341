/* eslint-disable */
import Socket from './socket';

export default class SocketManager {
  static instance;

  constructor() {
    if (SocketManager.instance) {
      return SocketManager.instance;
    }
    this.instance = this;
    this.socket = Socket();
  }

  addHandler(handle, cb) {
    this.socket.on(handle, function (data) {
      cb(data);
    });
  }

  removeHandler(handle, cb) {
    this.socket.off(handle, cb);
  }

  getSocket() {
    return this.socket;
  }

  getID() {
    return this.socket.id;
  }
}
