import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import flip2Outline from '@iconify/icons-eva/flip-2-outline';
import { Icon } from '@iconify/react';

// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Avatar, Box, Link, Drawer, Typography, Button } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { MHidden } from '../../components/@material-extend';
import { getSidebarConfig } from './SidebarConfig';
import useAuth from '../../hooks/useAuth';
import { doLogout } from '../../helpers';
import { getAllServers } from '../../api';
import { selectAllServers } from '../../store/selectors';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const match = useMatch({
    path: '/dashboard/:serverId',
    end: false
  });
  const servers = useSelector(selectAllServers);
  useEffect(() => {
    if (!servers) {
      getAllServers(currentUser, dispatch);
    }
  }, []);

  const toDashboard = () => navigate('/dashboard');
  const logout = () => doLogout(setCurrentUser, navigate);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!currentUser) {
    return null;
  }

  const server = servers && match?.params?.serverId ? servers[match.params.serverId] : null;

  const renderContent = (
    <Scrollbar
      sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={Button} onClick={onCloseSidebar} sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 2, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar
              src={`https://cdn.discordapp.com/avatars/${currentUser.id}/${currentUser.avatar}.png`}
              alt={`${currentUser.name}#${currentUser.discriminator}`}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {currentUser.name}#{currentUser.discriminator}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection server={server} navConfig={getSidebarConfig(server, { id: 'test' })} />
      {server && (
        <Box sx={{ px: 2.5, py: 3 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={toDashboard}
            startIcon={<Icon icon={flip2Outline} width={20} height={20} />}
          >
            Change server
          </Button>
        </Box>
      )}
      <Box sx={{ px: 2.5, py: 3 }}>
        <Button fullWidth color="error" variant="outlined" onClick={logout}>
          Logout
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
