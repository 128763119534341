import { createSlice } from '@reduxjs/toolkit';

const rolesSlice = createSlice({
  name: 'roles',
  initialState: {},
  reducers: {
    getRoles: (state, action) => {
      state[action.payload.id] = action.payload.response;
    }
  }
});

export const { getRoles } = rolesSlice.actions;
export default rolesSlice.reducer;
