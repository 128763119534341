import { createSlice } from '@reduxjs/toolkit';

const commandsSlice = createSlice({
  name: 'commands',
  initialState: {},
  reducers: {
    setServerCommands: (state, action) => {
      state[action.payload.id] = action.payload.response?.data;
    },
    updateServerCommands: (state, action) => {
      state[action.payload.id] = action.payload?.commands;
    },
    setBotCommands: (state, action) => {
      state.botCommands = action.payload?.commands;
    }
  }
});

export const { setServerCommands, updateServerCommands, setBotCommands } = commandsSlice.actions;
export default commandsSlice.reducer;
