import jwt_decode from 'jwt-decode'; // eslint-disable-line

export const isTokenValid = (token) => {
  const decode = jwt_decode(token);
  const exp = new Date(decode.exp * 1000);
  const now = new Date();

  return exp > now;
};

// Guild -> premium && premiumLevel >= 4 ? 3 : 1;
export const getGuildLimit = (server) => (server?.premiumLevel >= 4 ? 3 : 1);
// Guild roles -> premium ? 3 : 1;
export const getGuildRolesLimit = (server) => (server?.premium ? 3 : 1);
// Commands -> roles/channels: premium ? 5 : 3
export const getCommandLimit = (server) => (server?.premium ? 5 : 3);
// Rolemanagement -> custom + rolemap -> premium ? 3 : 1;
export const getRoleManagementLimit = (server) => (server?.premium ? 3 : 1);

export const syncLocalStorage = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('hasLinkedBnetAccount', user.hasLinkedBnetAccount);
};

export const clearLocalStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('hasLinkedBnetAccount');
};

export const doLogout = (setCurrentUser, navigate) => {
  setCurrentUser(null);
  clearLocalStorage();
  navigate('/');
};
