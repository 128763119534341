import { combineReducers, configureStore } from '@reduxjs/toolkit';
import settings from './settings.store';
import servers from './servers.store';
import integrations from './integrations.store';
import rolemanagement from './rolemanagement.store';
import general from './general.store';
import commands from './commands.store';
import roles from './roles.store';
import guilds from './guild.store';
import channels from './channels.store';
import specificServer from './specificServer.store';
import error from './error.store';
import characters from './characters.store';
import slashcommands from './slashcommands.store';

const appReducer = combineReducers({
  general,
  settings,
  integrations,
  rolemanagement,
  servers,
  commands,
  roles,
  guilds,
  channels,
  specificServer,
  error,
  characters,
  slashcommands
});

const store = configureStore({
  reducer: appReducer
});

export default store;
