import { createSlice } from '@reduxjs/toolkit';

const channelsSlice = createSlice({
  name: 'channels',
  initialState: {},
  reducers: {
    setChannels: (state, action) => {
      state[action.payload.id] = action.payload.response;
    }
  }
});

export const { setChannels } = channelsSlice.actions;
export default channelsSlice.reducer;
