import { createSlice } from '@reduxjs/toolkit';

const specificServersSlice = createSlice({
  name: 'specificServer',
  initialState: {},
  reducers: {
    setSpecificServer: (state, action) => {
      state[action.payload.id] = action.payload.data;
    }
  }
});

export const { setSpecificServer } = specificServersSlice.actions;
export default specificServersSlice.reducer;
