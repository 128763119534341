import { createSlice } from '@reduxjs/toolkit';

const slashCommandsSlice = createSlice({
  name: 'slashcommands',
  initialState: {},
  reducers: {
    setSlashCommands: (state, action) => {
      const obj = {};
      action.payload.response?.data?.forEach((c) => (obj[c.slug] = c));
      if (!state[action.payload.id]) {
        state[action.payload.id] = {
          commands: obj,
          global: null
        };
      } else {
        state[action.payload.id].commands = obj;
      }
    },
    setGlobalSlashCommands: (state, action) => {
      if (!state[action.payload.id]) {
        state[action.payload.id] = {
          commands: null,
          global: action.payload.response?.data
        };
      } else {
        state[action.payload.id].global = action.payload.response?.data;
      }
    },
    updateSlashCommand: (state, action) => {
      state[action.payload.id].commands[action.payload.slug] = {
        ...state[action.payload.id].commands[action.payload.slug],
        ...action.payload.command
      };
    },

    updateGlobalSlashCommand: (state, action) => {
      state[action.payload.id].commands.global = {
        ...state[action.payload.id].commands.global,
        ...action.payload.command
      };
    }
  }
});

export const { setSlashCommands, setGlobalSlashCommands, updateSlashCommand, updateGlobalSlashCommand } =
  slashCommandsSlice.actions;
export default slashCommandsSlice.reducer;
