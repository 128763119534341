import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { FavoriteBorderOutlined } from '@material-ui/icons';
import { updateCharacterSettings } from '../../api/battlenet.api';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: '5px'
  },
  failedChar: {
    borderColor: 'red',
    opacity: 0.4,
    transition: 'opacity 1s linear'
  },
  ignoreChar: {
    opacity: 0.4,
    transition: 'opacity 1s linear'
  },
  columns: {
    display: 'flex',
    flexDirection: 'column'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  avatar: {
    height: '70px',
    width: '70px',
    margin: '5px'
  }
});

const CharacterProfile = (props) => {
  const classes = useStyles();
  const characterInfo = props.data;
  const [visible, setVisibility] = React.useState(characterInfo.hidden);
  const [ignore, setIgnore] = React.useState(characterInfo.ignored);
  const QUEUED = characterInfo.stage === 'QUEUED';
  const SCANNED = characterInfo.stage === 'SCANNED';
  const FAILED = characterInfo.stage === 'FAILED';

  const updateMain = (bool) => {
    updateCharacterSettings(characterInfo.accountID, characterInfo.id, { main: bool }, props.token);
    props.setCurrentMain(bool ? characterInfo.id : null);
  };

  const updateVisible = (visible) => {
    updateCharacterSettings(characterInfo.accountID, characterInfo.id, { hidden: visible }, props.token);
    setVisibility(visible);
  };

  const updateIgnore = (ignore) => {
    updateCharacterSettings(characterInfo.accountID, characterInfo.id, { ignored: ignore }, props.token);
    setIgnore(ignore);
  };

  React.useEffect(() => setVisibility(characterInfo.hidden), [characterInfo.hidden]);
  React.useEffect(() => setIgnore(characterInfo.ignored), [characterInfo.ignored]);

  return (
    <Slide direction="up" in mountOnEnter unmountOnExit>
      <Paper
        variant="outlined"
        className={`${classes.root} ${FAILED ? classes.failedChar : ''} ${ignore ? classes.ignoreChar : ''}`}
        key={`${characterInfo.region}-${characterInfo.id}`}
      >
        <Grid container spacing={3} justify="center" alignItems="center">
          <Grid item xs={3}>
            <Avatar src={characterInfo.thumbnail} className={classes.avatar} />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {characterInfo.name} - {characterInfo.realm}({characterInfo.region})
            </Typography>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {characterInfo.level} {characterInfo.race} {characterInfo.spec ? characterInfo.spec : ''}{' '}
              {characterInfo.class}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {QUEUED ? <CircularProgress color="primary" style={{ width: '4rem', height: '4rem' }} /> : ''}
            {SCANNED ? (
              <div>
                {!ignore ? (
                  <>
                    <Tooltip title={characterInfo.main ? 'Remove as main' : 'Set as main'} aria-label="main">
                      {characterInfo.main ? (
                        <FavoriteIcon onClick={() => updateMain(false)} />
                      ) : (
                        <FavoriteBorderOutlined onClick={() => updateMain(true)} />
                      )}
                    </Tooltip>
                    <Tooltip title={visible ? 'Show this character' : 'Hide this character'} aria-label="hide">
                      {!visible ? (
                        <VisibilityIcon onClick={() => updateVisible(!visible)} />
                      ) : (
                        <VisibilityOffIcon onClick={() => updateVisible(!visible)} />
                      )}
                    </Tooltip>
                  </>
                ) : (
                  ''
                )}
                {!characterInfo.main ? (
                  <Tooltip title={ignore ? 'Unignore Character' : 'Ignore Character'} aria-label="ignore">
                    <NotInterestedIcon onClick={() => updateIgnore(!ignore)} />
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}
            {FAILED ? 'Failed to sync.' : ''}
          </Grid>
        </Grid>
      </Paper>
    </Slide>
  );
};

export default CharacterProfile;
