// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name, folder = 'navbar') => (
  <SvgIconStyle src={`/static/icons/${folder}/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  settings: getIcon('monospace', 'editor'),
  commands: getIcon('ic_analytics'),
  roleManagement: getIcon('ic_user'),
  integrations: getIcon('ic_dashboard')
};

export const getSidebarConfig = (server) => {
  if (!server) {
    return [];
  }

  return [
    {
      subheader: server.name,
      items: [
        { title: 'Settings', path: `/dashboard/${server.id}/settings`, icon: ICONS.settings },
        {
          title: 'Commands',
          path: `/dashboard/${server.id}/commands`,
          icon: ICONS.commands
        },
        { title: 'Role Management', path: `/dashboard/${server.id}/rolemanagement`, icon: ICONS.integrations },
        // { title: 'Integrations', path: `/dashboard/${server.id}/integrations`, icon: ICONS.integrations },
        { title: 'Guild', path: `/dashboard/${server.id}/guild`, icon: ICONS.roleManagement }
      ]
    }
  ];
};
