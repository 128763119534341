import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useAuth from '../../hooks/useAuth';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MAvatar, MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import { startLogin } from '../../api';
import { doLogout } from '../../helpers';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const navigate = useNavigate();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const { currentUser, setCurrentUser } = useAuth();
  const doLogin = () => startLogin(setCurrentUser);
  const handleLogout = () => {
    doLogout(setCurrentUser, navigate);
  };

  const filteredNavConfig = navConfig.filter((c) => c.title !== 'Dashboard' || currentUser);

  return (
    <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Label color="info" sx={{ ml: 1 }}>
            Jeeves Bot
          </Label>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={filteredNavConfig} />
          </MHidden>

          {!currentUser && (
            <Button variant="contained" target="_blank" onClick={doLogin}>
              Login
            </Button>
          )}
          {currentUser && <UserInfo user={currentUser} logout={handleLogout} />}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={filteredNavConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}

const UserInfo = ({ user, logout }) => (
  <>
    <MAvatar
      src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`}
      alt={`${user.name}#${user.discriminator}`}
      className="avatar"
    />{' '}
    <Label sx={{ ml: 1 }}>
      {user.name}#{user.discriminator}
    </Label>
    <Button variant="outlined" color="error" target="_blank" onClick={logout}>
      Logout
    </Button>
  </>
);

UserInfo.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func
};
