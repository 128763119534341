import { createSlice } from '@reduxjs/toolkit';

const guildSlice = createSlice({
  name: 'guilds',
  initialState: {},
  reducers: {
    setGuilds: (state, action) => {
      state[action.payload.id] = action.payload.data;
    },
    setGuildRolemap: (state, action) => {
      state[`${action.payload.serverId}-${action.payload.guildId}`] = action.payload.data;
    },
    setGuildRolemapExample: (state, action) => {
      state[`${action.payload.serverId}-${action.payload.guildId}-example`] = action.payload.data;
    }
  }
});

export const { setGuilds, setGuildRolemap, setGuildRolemapExample } = guildSlice.actions;
export default guildSlice.reducer;
