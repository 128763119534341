import { createSlice } from '@reduxjs/toolkit';

const integrationSlice = createSlice({
  name: 'integrations',
  initialState: {},
  reducers: {
    getIntegrations: (state, action) => {
      state[action.payload.id] = action.payload.response;
    }
  }
});

export const { getIntegrations } = integrationSlice.actions;
export default integrationSlice.reducer;
