import { createSlice } from '@reduxjs/toolkit';

const charactersSlice = createSlice({
  name: 'characters',
  initialState: {},
  reducers: {
    setCharacters: (state, action) => {
      state = action.payload;
    }
  }
});

export const { setCharacters } = charactersSlice.actions;
export default charactersSlice.reducer;
