import { useEffect } from 'react';
import jwt_decode from 'jwt-decode'; // eslint-disable-line
import { useNavigate } from 'react-router';
import * as Sentry from '@sentry/react';
import { Typography, Alert, Container, AlertTitle, Button } from '@material-ui/core';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from './components/NotistackProvider';
import useAuth from './hooks/useAuth';
import { syncLocalStorage, doLogout } from './helpers';
import Page from './components/Page';

// ----------------------------------------------------------------------

export default function App() {
  const { currentUser, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const localStorageUser = window.localStorage.getItem('user');
    const user = localStorageUser ? JSON.parse(localStorageUser) : undefined;
    const token = user?.bearerToken;
    if (token) {
      const decode = jwt_decode(token);
      const exp = new Date(decode.exp * 1000);
      const now = new Date();

      if (exp > now) {
        if (!currentUser || !currentUser.bearerToken || currentUser.bearerToken === '') {
          setCurrentUser(user);
          syncLocalStorage(user);
        }
      } else {
        doLogout(setCurrentUser, navigate);
      }
    }
  }, [navigate, currentUser, setCurrentUser]);
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <RtlLayout>
            <Settings />
            <ScrollToTop />
            <Sentry.ErrorBoundary
              fallback={({ error, componentStack }) => {
                <Page title="Jeeves Bot">
                  <Container maxWidth="xl">
                    <Alert
                      severity="error"
                      action={
                        <Button color="inherit" size="small" onClick={() => navigate('/discord')}>
                          REPORT
                        </Button>
                      }
                    >
                      <AlertTitle>Something went wrong, please report this on the Jeeves Discord.</AlertTitle>
                      {error}
                      <br />
                      <Typography variant="caption" display="block">
                        {componentStack}
                      </Typography>{' '}
                    </Alert>
                  </Container>
                </Page>;
              }}
            >
              <Router />
            </Sentry.ErrorBoundary>
          </RtlLayout>
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
