import { put, get, request, patch } from './request';
import { getServers } from '../store/servers.store';
import { getSettings, updateSettings } from '../store/settings.store';
import { getIntegrations } from '../store/integrations.store';
import { getRoles } from '../store/roles.store';
import { setChannels } from '../store/channels.store';
import { getRoleManagement, updateRoleManagement } from '../store/rolemanagement.store';
import { setServerCommands, updateServerCommands } from '../store/commands.store';
import { setSpecificServer } from '../store/specificServer.store';
import {
  setGlobalSlashCommands,
  setSlashCommands,
  updateSlashCommand,
  updateGlobalSlashCommand
} from '../store/slashcommands.store';
import { handleResponseError } from './apiError';

export async function getAllServers(user, dispatch) {
  if (!user || !user.id) {
    return;
  }
  const data = await request(`/user/${user.id}/servers`, true);
  if (!data || data?.status === 'error') {
    const message = data?.message || 'There was an issue fetching your servers. Please try again later!';
    handleResponseError(data, message, dispatch);
    return;
  }
  dispatch(getServers(data));
}

export async function getSpecificServer(serverId, dispatch) {
  const response = await request(`/server/${serverId}`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching this specific server.';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    setSpecificServer({
      id: serverId,
      data: response
    })
  );
}

export async function getServerSettings(serverId, dispatch) {
  const response = await request(`/server/${serverId}/settings/general`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your server settings. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    getSettings({
      id: serverId,
      response
    })
  );
}

export async function putServerSettings(serverId, settings, dispatch) {
  delete settings.name;
  const response = await put(`/server/${serverId}/settings/general`, { ...settings });

  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue updating your server settings. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }

  dispatch(
    updateSettings({
      id: serverId,
      settings
    })
  );
}

export async function getServerCommands(serverId, dispatch) {
  const response = await request(`/server/${serverId}/settings/command`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your server commands. Please try again later!';
    handleResponseError(response, message, dispatch);

    return;
  }
  dispatch(
    setServerCommands({
      id: serverId,
      response
    })
  );
}

export async function putServerCommands(serverId, commands, dispatch) {
  const response = await put(`/server/${serverId}/settings/command`, { ...commands });
  if (!response || response.status === 'error') {
    const message = response?.message || 'There was an issue updating your commands. Please try again later!';
    handleResponseError(response, message, dispatch);

    return;
  }
  dispatch(
    updateServerCommands({
      id: serverId,
      commands
    })
  );
}

export async function getServerRoleManagement(serverId, dispatch) {
  const response = await request(`/server/${serverId}/settings/rolemanagement`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your role management. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    getRoleManagement({
      id: serverId,
      response
    })
  );
}

export async function putServerRoleManagement(serverId, roleManagement, dispatch) {
  const response = await put(`/server/${serverId}/settings/rolemanagement`, { ...roleManagement });

  if (!response || response.status === 'error') {
    const message = response?.message || 'There was an issue updating your role management. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    updateRoleManagement({
      id: serverId,
      roleManagement
    })
  );
}

export async function getServerIntegrations(serverId, dispatch) {
  const response = await request(`/server/${serverId}/settings/rolemanagement/integrations`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your integrations. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    getIntegrations({
      id: serverId,
      response
    })
  );
}

export async function getBattlenetWatch(bnetId, socketId, bearerToken) {
  return get(`/battlenet/${bnetId}/watch`, {
    socket: socketId,
    Authorization: `Bearer ${bearerToken}`
  });
}

export async function getServerRoles(serverId, dispatch) {
  const response = await request(`/server/${serverId}/roles`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your roles. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    getRoles({
      id: serverId,
      response
    })
  );
}

export async function getServerChannels(serverId, dispatch) {
  const response = await request(`/server/${serverId}/channels`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your channels. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    setChannels({
      id: serverId,
      response
    })
  );
}

export async function getSlashCommands(serverId, dispatch) {
  const response = await request(`/server/${serverId}/settings/command`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your commands. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    setSlashCommands({
      id: serverId,
      response
    })
  );
}

export async function putSlashCommand(serverId, slug, command, dispatch) {
  const response = await patch(`/server/${serverId}/settings/command/${slug}`, { ...command });
  // PLEASE PUT PROPER RESPONSE CHECK BACK ASAP!
  if (response && response.status === 'error') {
    const message = response?.message || 'There was an issue updating your commands. Please try again later!';
    handleResponseError(response, message, dispatch);

    return;
  }
  dispatch(
    updateSlashCommand({
      id: serverId,
      slug,
      command
    })
  );
}

export async function getGlobalSlashCommandSettings(serverId, dispatch) {
  const response = await request(`/server/${serverId}/settings/command/global`, true);
  if (!response || response?.status === 'error') {
    const message = response?.message || 'There was an issue fetching your commands. Please try again later!';
    handleResponseError(response, message, dispatch);
    return;
  }
  dispatch(
    setGlobalSlashCommands({
      id: serverId,
      response
    })
  );
}

export async function putGlobalSlashCommandSettings(serverId, command, dispatch) {
  const response = await patch(`/server/${serverId}/settings/command/global`, { ...command });
  // PLEASE PUT PROPER RESPONSE CHECK BACK ASAP!
  if (response && response.status === 'error') {
    const message = response?.message || 'There was an issue updating your commands. Please try again later!';
    handleResponseError(response, message, dispatch);

    return;
  }
  dispatch(
    updateGlobalSlashCommand({
      id: serverId,
      command
    })
  );
}
