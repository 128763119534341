import { createSlice } from '@reduxjs/toolkit';

const rolemanagementSlice = createSlice({
  name: 'rolemanagement',
  initialState: {},
  reducers: {
    getRoleManagement: (state, action) => {
      state[action.payload.id] = action.payload.response.data;
    },
    updateRoleManagement: (state, action) => {
      state[action.payload.id] = {
        ...state[action.payload.id],
        ...action.payload.roleManagement,
        integrations: {
          ...state[action.payload.id].integrations,
          ...action.payload.roleManagement.integrations
        }
      };
    }
  }
});

export const { getRoleManagement, updateRoleManagement } = rolemanagementSlice.actions;
export default rolemanagementSlice.reducer;
