import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: {},
  reducers: {
    setError: (state, action) => {
      state[action.payload.id] = action.payload.response.data;
    },
    clearAll: (state) => {
      state = {};
      return state;
    },
    clearError: (state, action) => {
      delete state[action.payload?.path || window.location.pathname];
    },
    setResponseError: (state, action) => {
      state[window.location.pathname] =
        action.payload.response?.message || action.payload.message || 'Something went wrong. Please try again later!';
    }
  }
});

export const { setResponseError, clearError } = errorSlice.actions;
export default errorSlice.reducer;
