import queryString from 'query-string';

import { Button, Card, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SocketManager from '../../api/SocketManager';
import Battlenet from './Battlenet';
import useAuth from '../../hooks/useAuth';
import { getBnetSession } from '../../api/battlenet.api';

const socketManager = new SocketManager();

export default () => {
  const [showBnet, setBnet] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const params = queryString.parse(location.search);

  const goHome = () => navigate('/');

  getBnetSession(currentUser.bearerToken).then((resp) => {
    console.log(resp);
  });

  // ensure user exists and is a number
  if (!params || !params.user || Number.isNaN(parseInt(params.user, 10))) {
    return (
      <Grid container style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <Card>
            <h2>You seem to be in the wrong place.</h2>
            <Button onClick={goHome}>Go back</Button>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return showBnet ? (
    <Battlenet socket_manager={socketManager} setBnet={setBnet} user={params.user} />
  ) : (
    <Grid container style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <Card>
          <h2>Battle.net Authentication with Jeeves</h2>
          <Button onClick={() => setBnet(true)} fullWidth>
            Start!
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
};
