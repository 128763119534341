// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    settings: path(ROOTS_DASHBOARD, '/:serverId/commands'),
    commands: path(ROOTS_DASHBOARD, '/:serverId/commands'),
    roleManagement: path(ROOTS_DASHBOARD, '/:serverId/rolemanagement'),
    integrations: path(ROOTS_DASHBOARD, '/:serverId/integrations')
  }
};
