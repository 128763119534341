import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src="/static/jeeves.jpg" alt="Jeeves Logo" style={{ borderRadius: 20 }} />
    </Box>
  );
}
