import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

const AuthContext = React.createContext(null);

const AuthProvider = ({ user, children }) => {
  const [currentUser, setCurrentUser] = useState(user);
  useEffect(() => {
    if (currentUser) {
      Sentry.setUser({ id: currentUser.id });
    } else {
      Sentry.setUser(null);
    }
  }, [currentUser]);

  return <AuthContext.Provider value={{ currentUser, setCurrentUser }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export { AuthContext, AuthProvider };
