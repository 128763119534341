/* eslint-disable */
import { get, updateWithToken } from './request';

export async function getBattlenetWatch(bnetId, socketId, bearerToken) {
  return await get(`/battlenet/${bnetId}/watch`, {
    socket: socketId,
    Authorization: `Bearer ${bearerToken}`
  });
}

export async function updateCharacterSettings(userID, characterID, obj, token) {
  return await updateWithToken(`/battlenet/${userID}/characters/${characterID}`, obj, token);
}

export async function getBnetSession(bearerToken) {
  return await get(`/auth/battlenet/session`, {
    Authorization: `Bearer ${bearerToken}`
  });
}
