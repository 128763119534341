import { uuid } from 'uuidv4';
import { request } from './request';
import { getInfo } from '../store/general.store';
import { syncLocalStorage } from '../helpers';

export async function getBotInfo(dispatch) {
  const response = await request('/bot/stats');
  dispatch(getInfo(response));
}

export function startLogin(setCurrentUser) {
  const sessionId = uuid();
  const popupWin = window.open(
    `${process.env.REACT_APP_LOGIN}/auth/discord?sessionId=${sessionId}`,
    'llkg login',
    'menubar=no,width=500,height=720,location=yes,resizable=no,scrollbars=yes,status=no'
  );

  const checkLoginStatus = async () => {
    const response = await request(`/auth/discord/status?sessionId=${sessionId}`);
    if (response.bearerToken && response.bearerToken !== '') {
      setCurrentUser(response);
      syncLocalStorage(response);
      clearInterval(timer);
      popupWin?.close();
    }
  };

  const timer = setInterval(() => {
    checkLoginStatus();
    if (popupWin.closed) {
      clearInterval(timer);
    }
  }, 1000);

  // Stop checking after 10 minutes no matter what
  setTimeout(() => {
    if (timer) {
      clearInterval(timer);
    }
  }, 10 * 60 * 1000);
}

export function startInvite() {
  window.open(
    `${process.env.REACT_APP_LOGIN}/invite`,
    'llkg invite',
    'menubar=no,width=500,height=720,location=yes,resizable=no,scrollbars=yes,status=no'
  );
}

export async function getJeevesCommands() {
  return request('/bot/commands');
}
