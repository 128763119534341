export const selectInfo = (state) => state?.general?.info;
export const selectAllBotCommands = (state) => state?.commands?.botCommands;
export const selectBotCommands = (group) => (state) => state?.commands?.botCommands?.[group];

// server stuff
export const selectAllServers = (state) => state?.servers;
export const selectServer = (serverId) => (state) => state?.servers?.[serverId];
export const selectSettings = (serverId) => (state) => state?.settings?.[serverId];
export const selectChannels = (serverId) => (state) => state?.channels?.[serverId];
export const selectCommands = (serverId) => (state) => state?.commands?.[serverId];
export const selectRoles = (serverId) => (state) => state?.roles?.[serverId];
export const selectIntegrations = (serverId) => (state) => state?.integrations?.[serverId];
export const selectRoleManagement = (serverId) => (state) => state?.rolemanagement?.[serverId];
export const selectSpecificServer = (serverId) => (state) => state?.specificServer?.[serverId];
export const selectSlashCommands = (serverId) => (state) => state?.slashcommands?.[serverId]?.commands;
export const selectGlobalSlashCommandSettings = (serverId) => (state) => state?.slashcommands?.[serverId]?.global;

// guilds
export const selectAllGuilds = (serverId) => (state) => state?.guilds?.[serverId];
export const selectGuildRolemap = (serverId, guildId) => (state) => state?.guilds?.[`${serverId}-${guildId}`];
export const selectGuildRolemapExample = (serverId, guildId) => (state) =>
  state?.guilds?.[`${serverId}-${guildId}-example`];
